import React, { useEffect, useState, useRef } from 'react';
import appStore from '../assets/images/Melon-Mobile-Download-App-Store.svg';
import googlePlay from '../assets/images/Melon-Mobile-Download-Google-Play.svg';
import animationData from '../assets/lottie/Melon-Mobile-CTA-Animation.json';
import { useInView } from 'react-intersection-observer';
import HeadingTwo from "./HeadingTwo";

import '@lottiefiles/lottie-player';
import { create } from "@lottiefiles/lottie-interactivity";

interface ctaProps {
    image: string,
    title: string,
    copy: string
}
interface Props {
    CTA: boolean;
    className: string
  }
function DownloadCta({image, title, copy}: ctaProps) {
  const [inView, setInView] = useState(false);
  const headingRef = React.useRef<HTMLImageElement>(null);
  const [ref, inView2] = useInView({
    triggerOnce: true,
    rootMargin: '200px'
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    direction: 'forward',
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setInView(true);
        }
      });
    });
    if (headingRef.current) {
      observer.observe(headingRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [headingRef]);


  const LottieRef = useRef<any>();


    useEffect(()=>{
      LottieRef.current.addEventListener('load', ()=>{
        create({
          src: 'https://assets3.lottiefiles.com/packages/lf20_eB9nIDhJZ9.json',
          mode: 'scroll',
          player: '#firstLottie',
          actions:[
            {
              visibility: [0.3, 1], // Original 0, 1
              type: "seek",
              frames: [0, 60]
            }
          ]
        })
      })
    },[])

  return (
    <div className='container ai-py-4'>
      <div className="download-cta green">
            <div className="row">
                <div className="col-12 col-lg-6 text">
                    <div ref={headingRef}
                    className={`reveal-heading ${inView ? 'slide-up' : ''}`}><HeadingTwo title={title}/></div>
                    <div dangerouslySetInnerHTML={{ __html: copy }} />
                    <div className="stores">
                        <a className="mb-3 mb-md-0 mb-lg-3 d-inline-block" href="https://www.apple.com/app-store/" target={'_blank'} rel="noreferrer">
                            <img src={appStore} alt="App Store" loading='lazy' width='160px' height='100%' />
                        </a>
                        <a href="https://play.google.com/store/apps?hl=en&gl=US" target={'_blank'} rel="noreferrer">
                            <img src={googlePlay} alt="Google Play Store" loading='lazy' width='170px' height='100%' />
                        </a>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className='cta-image d-none d-lg-block'>
                    <lottie-player class={'ai-cta-lottie'} ref={LottieRef} id="firstLottie" src={'https://assets3.lottiefiles.com/packages/lf20_eB9nIDhJZ9.json'} mode={"normal"}></lottie-player>
                  </div>
                  <img className='main-image d-lg-none' src={image} alt="Download CTA" loading='lazy' width={600} height="100" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default DownloadCta