import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import { useInView } from 'react-intersection-observer';

//Animations
import studenPlan from '../assets/lottie/melon-mobile-student-melon-animation.json';
import familyPlan from '../assets/lottie/melon-mobile-family-melon-animation.json';
import influencerPlan from '../assets/lottie/melon-mobile-influencer-melon-animation.json';
import gamerPlan from '../assets/lottie/melon-mobile-gamer-melon-animation.json';
import travellerPlan from '../assets/lottie/melon-mobile-traveller-melon.-animationjson.json';
import businessPlan from '../assets/lottie/melon-mobile-business-melon-animation.json';

//Icons
import icon1 from '../assets/images/packages/Melon-Mobile-Try-Before-You-Buy-2.svg';
import icon2 from '../assets/images/packages/Melon-Mobile-Student-Plan.svg';
import icon3 from '../assets/images/packages/Melon-Mobile-Family-Plan.svg';
import icon4 from '../assets/images/packages/Melon-Mobile-Influencer-Plan.svg';
import dataIcon from '../assets/images/packages/Melon-Mobile-Data-Dark-Icon.svg';
import callIcon from '../assets/images/packages/Melon-Mobile-Call-Dark-Icon.svg';
import smsIcon from '../assets/images/packages/Melon-Mobile-Text-Dark-Icon.svg';
import dataIconWhite from '../assets/images/packages/Melon-Mobile-Data-light-Icon.svg';
import callIconWhite from '../assets/images/packages/Melon-Mobile-Call-light-Icon.svg';
import smsIconWhite from '../assets/images/packages/Melon-Mobile-Text-light-Icon.svg';
import HeadingHardcoded from './HeadingHardcoded';
import Button from './Button';

function PackagesTiles() {
    const [inView, setInView] = useState(false);
    const headingRef = React.useRef<HTMLImageElement>(null); 
    const lottieRef = React.useRef<HTMLImageElement>(null);
    const [ref, inView2] = useInView({
        triggerOnce: true,
        rootMargin: '200px'
      });

      useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (lottieRef.current) {
          observer.observe(lottieRef.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [lottieRef]);

    //Student plan animation
    const studentPlanOptions = {
        loop: true,
        autoplay: true, 
        animationData: studenPlan,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };
    //Family plan animation
    const familyPlanOptions = {
        loop: true,
        autoplay: true, 
        animationData: familyPlan,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };
    //Influencer plan animation
    const influencerPlanOptions = {
        loop: true,
        autoplay: true, 
        animationData: influencerPlan,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };
    //gamer plan animation
    const gamerPlanOptions = {
        loop: true,
        autoplay: true, 
        animationData: gamerPlan,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };
    //traveller plan animation
    const travellerPlanOptions = {
        loop: true,
        autoplay: true, 
        animationData: travellerPlan,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };
    //Business plan animation
    const businessPlanOptions = {
        loop: true,
        autoplay: true, 
        animationData: businessPlan,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (headingRef.current) {
          observer.observe(headingRef.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [headingRef]);
    
    const packagesData: any = [
        {
            id: 1,
            title: 'Try Before You Buy',
            data: '1GB',
            call: '100 Min',
            text: '100 Texts',
            price: 'FREE',
            icon: icon1,
            link: '/deals'
        },
        {
            id: 2,
            title: 'Student Melon',
            data: '20 GB',
            call: '20 Min',
            text: '150 Texts',
            price: 'R250',
            icon: icon2,
            link: '/packages/student'
        },
        {
            id: 3,
            title: 'Family Melon',
            data: '50 GB',
            call: '200 Min',
            text: '200 Texts',
            price: 'R450',
            icon: icon3,
            link: '/packages/family-melon'
        },
        {
            id: 4,
            title: 'Influencer Melon',
            data: '40 GB',
            call: '50 Min',
            text: '50 Texts',
            price: 'R400',
            icon: icon4,
            link: '/packages/influencer-melon'
        }
    ];

  return (
    <div className='packages-list'>
        <div className="container">
            <div className="row">
                {packagesData.map((item:any) => (
                <div key={item.id} className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
                <div className={item.price == 'FREE' ? 'deal free' : 'deal'}>
                    <div className="header">
                        <HeadingHardcoded title={item.title} />
                    </div>
                    <div className="body">
                        <div className="icon">
                            {
                                item.title == "Try Before You Buy" &&
                                <img src={item.icon} alt={item.title} className='try-before-you-buy-icon' />
                            }
                            {
                                item.title == "Student Melon" &&
                                <div ref={lottieRef}>
                                    <div className='d-block lottie-image' ref={ref}>
                                        <Lottie options={studentPlanOptions} height={150} width={150} isStopped={!inView} />
                                    </div>
                                </div>
                            }
                            {
                                item.title == "Family Melon" &&
                                <div ref={lottieRef}>
                                    <div className='d-block lottie-image' ref={ref}>
                                        <Lottie options={familyPlanOptions} height={150} width={150} isStopped={!inView} />
                                    </div>
                                </div>
                            }
                            {
                                item.title == "Influencer Melon" &&
                                <div ref={lottieRef}>
                                    <div className='d-block lottie-image' ref={ref}>
                                        <Lottie options={influencerPlanOptions} height={150} width={150} isStopped={!inView} />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="details">
                            <p>
                                <img src={dataIcon} alt="Data" /> 
                                <strong> Data</strong> {item.data}
                            </p>
                            <p>
                                <img src={callIcon} alt="Call" /> 
                                <strong> Call</strong> {item.call}
                            </p>
                            <p>
                                <img src={smsIcon} alt="Text" />
                                <strong>SMS</strong> {item.text}
                            </p>
                        </div>

                        <div className="price">
                            <p><strong>{item.price}</strong> {item.price == 'FREE' ? <span>for 1 month</span> : <span>per month</span>}</p>
                        </div>

                        <div className="link">
                            <Button 
                                type='button'
                                className='btn btn-primary'
                                link={item.link}
                                linkText= {item.price == 'FREE' ? 'Learn more' : 'See plan details'}
                            />
                        </div>
                    </div>
                </div>
            </div>

                ))}
            </div>
        </div>
    </div>
  )
}

export default PackagesTiles