import React, { useState, useEffect } from 'react';
import {useLoaderData} from "react-router-dom";
import { PageData } from "../types/PageData";
import Banner from '../components/Banner';
import VideoBanner from '../components/VideoBanner';
import background from '../assets/images/Melon-Mobile-Banner-Background.jpg';
import ContentImage from '../components/ContentImage';
import DownloadCta from '../components/DownloadCta';
import image1 from '../assets/images/Melon-Mobile-Your-Plan-Made-Your-Way-CTA.png';
import SupportCta from '../components/SupportCta';
import Accordion from '../components/Accordion';
import {Helmet} from 'react-helmet';
import HeadingTwo from '../components/HeadingTwo';
import Navigation from '../components/Navigation';


import Lottie from 'react-lottie';
import { useInView } from 'react-intersection-observer';

//Animations
import howItWorks from '../assets/lottie/melon-mobile-how-it-works-animation.json';

const HowItWorks = () => {
    // Page Data From Router
    const PageData = useLoaderData() as PageData;
    const items = PageData.content.no_short_codes;
    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;
    const [selectedChild, setSelectedChild] = useState<string | null>(null);
    const yoast:any = PageData.yoast_head_json
    const [inView, setInView] = useState(false);
    const lottieRef = React.useRef<HTMLImageElement>(null);
    const [ref, inView2] = useInView({
        triggerOnce: true,
        rootMargin: '200px'
      });

      useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (lottieRef.current) {
          observer.observe(lottieRef.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [lottieRef]);

    //How it works animation
    const howItWorksOptions = {
        loop: true,
        autoplay: true, 
        animationData: howItWorks,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };


  const handleClick = (childId: string) => {
        setSelectedChild(childId);
    };

    const ctaData:any = {
        title:'It’s your plan made your way.',
        copy: '<p>The first step to digital freedom takes less than a minute. Download the Melon App now.</p>',
        image: image1
    }

    useEffect(() => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);

  return (
    <div className='how-it-works'>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : 'Melon Mobile | '+PageData.title.rendered}</title>
            <meta name="description" content='Go your own way'/>
        </Helmet>
        <Navigation />
        <Banner 
            badge={PageData.title.rendered} 
            title={items[0][0][0]} 
            background={background} 
        />

        <div className="ai-intro-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <div ref={lottieRef}>
                            {items[1][0] && <div className='d-lg-none' dangerouslySetInnerHTML={{ __html: items[1][0] }} />}
                            <div className='d-none d-lg-block lottie-image' ref={ref}>
                                <Lottie options={howItWorksOptions} height={150} width={470} isStopped={!inView} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-10 col-lg-8 mx-auto">
                        {items[1][1][0] === '' ? null : <HeadingTwo title={items[1][1][0]} />}
                        {items[1][1][1] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[1][1][1] }} />}
                    </div>
                </div>
            </div>
        </div>

        <VideoBanner 
            video={items[2][1]+items[2][2]} 
            videoThumbnail={items[2][0]} 
        />

        <ContentImage 
            layout='image left'
            image={items[3][0]}
            numbers={items[3][1]}
            title={items[3][2]}
            copy={items[3][3]}
            link={items[3][4].replace(aiButtonHref, '$1')}
            linkText={items[3][4].replace(aiButtonHref, '$2')}
            list=''
            animation = {true}
            animationName = 'download-app'
        />

        <ContentImage 
            layout='image right'
            image={items[4][0]}
            numbers={items[4][1]}
            title={items[4][2]}
            copy={items[4][3]}
            link={items[4][4].replace(aiButtonHref, '$1')}
            linkText={items[4][4].replace(aiButtonHref, '$2')}
            list=''
            animation = {true}
            animationName = 'build-own-plan'
        />

        <ContentImage 
            layout='image left'
            image={items[5][0]}
            numbers={items[5][1]}
            title={items[5][2]}
            copy={items[5][3]}
            link={items[5][4].replace(aiButtonHref, '$1')}
            linkText={items[5][4].replace(aiButtonHref, '$2')}
            list=''
            animation = {true}
            animationName = 'activate-sim'
        />

        <DownloadCta 
            title={ctaData.title} 
            copy={ctaData.copy} 
            image={ctaData.image} 
        />

        <div className="container accordion-wrapper">
            {items[6][0][0] === '' ? null : <HeadingTwo title={items[6][0][0]} />}

            <Accordion 
                id="1" onClick={handleClick} isSelected={selectedChild === '1'}
                question={items[6][1][0]}
                answer={items[6][1][1]}
            />
            <Accordion 
                id="2" onClick={handleClick} isSelected={selectedChild === '2'}
                question={items[6][1][2]}
                answer={items[6][1][3]}
            />
            <Accordion 
                id="3" onClick={handleClick} isSelected={selectedChild === '3'}
                question={items[6][1][4]}
                answer={items[6][1][5]}
            />
            <Accordion
                id="4" onClick={handleClick} isSelected={selectedChild === '4'} 
                question={items[6][1][6]}
                answer={items[6][1][7]}
            />
            <Accordion
                id="5" onClick={handleClick} isSelected={selectedChild === '5'} 
                question={items[6][1][8]}
                answer={items[6][1][9]}
            />
            <Accordion
                id="6" onClick={handleClick} isSelected={selectedChild === '6'} 
                question={items[6][1][10]}
                answer={items[6][1][11]}
            />
        </div>

        <SupportCta />
    </div>
  );
};

export default HowItWorks;