import React, { useEffect, useState } from 'react'
import HeadingTwo from './HeadingTwo'

import Lottie from 'react-lottie';
import { useInView } from 'react-intersection-observer';

//Animations
import moreChoice from '../assets/lottie/melon-mobile-more-choice-animation.json';
import tryBeforeYouBuy from '../assets/lottie/melon-mobile-try-before-you-buy-animation.json';
import fullyDigital from '../assets/lottie/melon-mobile-fully-digital-animation.json';
import noContracts from '../assets/lottie/melon-mobile-no-contracts-animation.json';
import neverExpires from '../assets/lottie/melon-mobile-data-that-never-expires-animation.json';

interface moreBenefitsProps {
    title: string,
    image1: string,
    subtitle1: string,
    copy1: string,
    image2: string,
    subtitle2: string,
    copy2: string,
    image3: string,
    subtitle3: string,
    copy3: string,
    image4: string,
    subtitle4: string,
    copy4: string,
    image5: string,
    subtitle5: string,
    copy5: string,
    image6: string,
    subtitle6: string,
    copy6: string,
}

function MoreBenefits({...props}: moreBenefitsProps) {
    const [inView, setInView] = useState(false);
    const headingRef = React.useRef<HTMLImageElement>(null);
    const lottieRef = React.useRef<HTMLImageElement>(null);
    const [ref, inView2] = useInView({
        triggerOnce: true,
        rootMargin: '200px'
      });

      useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (lottieRef.current) {
          observer.observe(lottieRef.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [lottieRef]);

    //More Choice
    const moreChoiceOptions = {
        loop: true,
        autoplay: true, 
        animationData: moreChoice,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };
    //Try Before You buy
    const tryBeforeYouBuyOptions = {
        loop: true,
        autoplay: true, 
        animationData: tryBeforeYouBuy,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

     //Fully Digital
     const fullyDigitalOptions = {
        loop: true,
        autoplay: true, 
        animationData: fullyDigital,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    //Data that never Expires
    const neverExpiresOptions = {
        loop: true,
        autoplay: true, 
        animationData: neverExpires,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    //No contracts
    const noContactsOptions = {
        loop: true,
        autoplay: true, 
        animationData: noContracts,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };
  return (
    <div className='more-benefits'>
        <div className="container">
            {props.title === '' ? null : <HeadingTwo title={props.title} />}
            <div className="row justify-content-center">
                <div className="col-12 col-md-4 col-lg-3">
                    <div className="item">
                        <div ref={lottieRef}>
                            {props.image1 && <div className='d-lg-none' dangerouslySetInnerHTML={{ __html: props.image1 }} />}
                            <div className='d-none d-lg-block lottie-image' ref={ref}>
                                <Lottie options={moreChoiceOptions} height={150} width={150} isStopped={!inView} />
                            </div>
                        </div>
                        {props.subtitle1 === '' ? null : <h3>{props.subtitle1}</h3>}
                        {props.copy1 === '' ? null : <p>{props.copy1}</p>}
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                    <div className="item">
                        <div ref={lottieRef}>
                            {props.image2 && <div className='d-lg-none' dangerouslySetInnerHTML={{ __html: props.image2 }} />}
                            <div className='d-none d-lg-block lottie-image' ref={ref}>
                                <Lottie options={neverExpiresOptions} height={150} width={150} isStopped={!inView} />
                            </div>
                        </div>
                        {props.subtitle2 === '' ? null : <h3>{props.subtitle2}</h3>}
                        {props.copy2 === '' ? null : <p>{props.copy2}</p>}
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                    <div className="item">
                        <div ref={lottieRef}>
                            {props.image3 && <div className='d-lg-none' dangerouslySetInnerHTML={{ __html: props.image3 }} />}
                            <div className='d-none d-lg-block lottie-image' ref={ref}>
                                <Lottie options={noContactsOptions} height={150} width={150} isStopped={!inView} />
                            </div>
                        </div>
                        {props.subtitle3 === '' ? null : <h3>{props.subtitle3}</h3>}
                        {props.copy3 === '' ? null : <p>{props.copy3}</p>}
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-12 col-md-4 col-lg-3">
                    <div className="item">
                        <div ref={lottieRef}>
                            {props.image4 && <div className='d-lg-none' dangerouslySetInnerHTML={{ __html: props.image4 }} />}
                            <div className='d-none d-lg-block lottie-image' ref={ref}>
                                <Lottie options={fullyDigitalOptions} height={150} width={150} isStopped={!inView} />
                            </div>
                        </div>
                        {props.subtitle4 === '' ? null : <h3>{props.subtitle4}</h3>}
                        {props.copy4 === '' ? null : <p>{props.copy4}</p>}
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                    <div className="item">
                        <div ref={lottieRef}>
                            {props.image5 && <div className='d-lg-none' dangerouslySetInnerHTML={{ __html: props.image5 }} />}
                            <div className='d-none d-lg-block lottie-image' ref={ref}>
                                <Lottie options={tryBeforeYouBuyOptions} height={150} width={150} isStopped={!inView} />
                            </div>
                        </div>
                        {props.subtitle5 === '' ? null : <h3>{props.subtitle5}</h3>}
                        {props.copy5 === '' ? null : <p>{props.copy5}</p>}
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                    <div className="item">
                        <div ref={lottieRef}>
                            {props.image6 && <div className='d-block lottie-image' dangerouslySetInnerHTML={{ __html: props.image6 }} />}
                            {/* <div className='d-none d-lg-block lottie-image' ref={ref}>
                                <Lottie options={moreChoiceOptions} height={150} width={150} isStopped={!inView} />
                            </div> */}
                        </div>
                        {props.subtitle6 === '' ? null : <h3>{props.subtitle6}</h3>}
                        {props.copy6 === '' ? null : <p>{props.copy6}</p>}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MoreBenefits