import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { DateTime } from 'luxon';

//Helpers
import { setCookie, getCookie } from '../helpers/CookieHelper';
import { submitNewsLetter } from '../functions/sendData';

//Images
import logo from '../assets/images/Melon-Mobile-Logo.svg';
import icon from '../assets/images/Melon-Mobile-Subscribe-Mailing-List.svg';
import Button from './Button';



function SubscribeModal() {
    const [isOpen, setIsOpen] = useState(false);
    const [hasClass, setHasClass] = useState(false);
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const linkRef = useRef<HTMLAnchorElement>(null);
    const [hover, setHover] = useState(false);
    const [cookieName, setCookieName] = useState('mm-subscribe-banner');
    const [cookieExpiry, setCookieExpiry] = useState(14);
    
    const handleMouseEnter = () => {
        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
    };

    const handleHover = () => {
        if (hasClass) {
            setHasClass(false);
        } else {
            setHasClass(true);
            if (500) {
            setTimeout(() => {
                setHasClass(false);
            }, 500);
            }
        }
    };

    useEffect(() => {
        const cookie_banner = getCookie(cookieName);
        if ( !cookie_banner ){
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }


        const acceptedSubscribeNotice = localStorage.getItem('acceptedSubscribeNotice');
        if (acceptedSubscribeNotice === 'yes') {
          setIsOpen(false)
        }
    }, []);
        
    const handleAcceptSubscribeNotice = (action: string) => {
        // 1. Close pop up
        const current_date = new Date();
        const future_date = DateTime.fromISO( current_date.toISOString() ).plus({ days: 14 });
        const future_date_string = future_date.toJSDate();


        if ( action === 'no-thanks' ) {
            // Set the cookie.
            setCookie(cookieName, 'true', cookieExpiry);
            setIsOpen(false);
        } else {
            // Hide pop up but do not set cookie.
            setIsOpen(false);
        }
        // localStorage.setItem('acceptedSubscribeNotice', 'yes');
        // setIsOpen(false)
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setError('');
    };
    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
          setError('Invalid email address');
          return;
        }

        // Submit the newsletter.
        submitNewsLetter( email )
        .then( ( response: any ) => {
            // console.log(response);
            if( response && response.status === 'success' ){
                setCookie(cookieName, 'true', cookieExpiry);
                setIsOpen(false);
            } else {
                setError(response.message);
            }
        })
        .catch( ( error: any ) => {
            setError(error.message);
            // console.error(error);
        });

        // console.log(`Sent email: ${email}`);
        // setEmail('');
        // localStorage.setItem('acceptedSubscribeNotice', 'yes');
        // setIsOpen(false)
    };

  return (
    <div className={isOpen ? 'subscribe show' : 'subscribe hide'}>
        <div className="wrapper">
            <button className={`close ${hasClass ? '' : ""}`} data-animation='xMarks' data-remove={500} onMouseEnter={handleHover} onMouseLeave={() => setHasClass(false)}  onClick={()=>{handleAcceptSubscribeNotice('close')}}>
              </button>
            <div className="image">
                <img src={icon} alt="Subscribe" width='308' height='308'/>
            </div>
            <div className="content">
                <div className="logo"><img src={logo} alt="" width='213' height='65' /></div>
                <h4>You Got Melon Mail</h4>
                <p>Join our Melon mailing list and never miss any of the exciting things we have planned. We promise not to send anything seedy.</p>
                <form onSubmit={handleSubmit}>
                    <div className="input">
                        <input 
                            type="text"
                            value={email}
                            onChange={handleEmailChange}
                            placeholder="Email address"
                            
                        />
                    </div>
                    {error && <div className="error">{error} <br /> <br /></div>}
                    <button
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={{ position: 'relative' }}
                        className='btn btn-primary'
                        type='submit'
                    >
                        <span className="text">Sign me up</span>
                        <span
                            className='bg'
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: hover ? '300%' : 0,
                                height: hover ? '300%' : 0,
                                transition: 'all 0.2s ease-out',
                            }}
                        />
                    </button> <br />
                </form>
                <button
                        onClick={()=>{handleAcceptSubscribeNotice('no-thanks')}}
                        className='link'
                    >I'm okay for now thanks.</button>
            </div>
        </div>
    </div>
  )
}

export default SubscribeModal