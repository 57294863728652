import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import {motion} from "framer-motion";

function PromoSticker() {

  //State
  const [hover, setHover] = useState(false);
  const [cursorPos, setCursorPos] = useState({x:0, y:0})

  //Functions
  const handleMouseEnter = (event:any) => {
    setHover(true);
    let rect = event.target.getBoundingClientRect()
    setCursorPos({x: event.clientY - rect.top, y: event.clientX - rect.left })
  };

  const handleMouseLeave = (event:any) => {
    setHover(false);
    let rect = event.target.getBoundingClientRect()
    setCursorPos({x: event.clientY - rect.top, y: event.clientX - rect.left })
  };

  return (
      <>
        <NavLink to="/get-a-sim-promo" className="promo-sticker"
                 onMouseEnter={handleMouseEnter}
                 onMouseLeave={handleMouseLeave}
        >
          Get your <strong>FREE SIM</strong>
          <motion.span
              className={'bg'}
              style={{left: cursorPos.x, top: cursorPos.y}}
              initial={{scale: 0}}
              transition={{duration: .5}}
              animate={hover ? {scale: 60} : {scale: 0}}
          ></motion.span>
        </NavLink>
      </>
  )
}

export default PromoSticker