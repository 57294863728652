import { motion } from 'framer-motion';
import React, { useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface linkProps {
    link: string,
    linkText: string,
    className: string,
    type: string,
    linkType?: string
}


function Button({link, linkText, className, type, linkType}: linkProps) {
    const linkRef = useRef<HTMLAnchorElement>(null);
    const [hover, setHover] = useState(false);
    const [cursorPos, setCursorPos] = useState({x:0, y:0})
  
    const handleMouseEnter = (event:any) => {
      setHover(true);
      let rect = event.target.getBoundingClientRect()
      setCursorPos({x: event.clientX - rect.x, y: event.clientY - rect.top})
    };
  
    const handleMouseLeave = (event:any) => {
      setHover(false);
      let rect = event.target.getBoundingClientRect()
      setCursorPos({x: event.clientX - rect.left, y: event.clientY - rect.top})
    };
  
  return (
    linkType === 'external' ?
      <a href={link} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ position: 'relative' }} className={className} type='button' target='_blank'>
        <span className='text'>{linkText}</span>
         <motion.span
            className={'bg'}
            style={{left: cursorPos.x, top: cursorPos.y}}
            initial={{scale: 0}}
            transition={{duration: .8}}
            animate={hover ? {scale: 80} : {scale: 0}}
        ></motion.span>
      </a>: 
    <RouterLink
      to={link}
      ref={linkRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: 'relative' }}
      className={className}
      type={type}
    >
        <span className='text'>{linkText}</span>

        {
          className.includes('btn') &&
            <motion.span
                className={'bg'}
                style={{left: cursorPos.x, top: cursorPos.y}}
                initial={{scale: 0}}
                transition={{duration: .8}}
                animate={hover ? {scale: 80} : {scale: 0}}
            ></motion.span>
        }
    </RouterLink>
  )
}

export default Button