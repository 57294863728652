import React, { useEffect } from 'react';
import {useLoaderData} from "react-router-dom";
import { PageData } from "../types/PageData";
import DownloadCta from '../components/DownloadCta';
import {Helmet} from 'react-helmet';

import image1 from '../assets/images/Melon-Mobile-Your-Plan-Made-Your-Way-CTA.png';
import ContactBanner from '../components/ContactBanner';
import Navigation from '../components/Navigation';

const Contact = () => {
    // Page Data From Router
    const PageData = useLoaderData() as PageData
    const items:any = PageData.content.no_short_codes;
    const yoast:any = PageData.yoast_head_json


  useEffect(() => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);

    const ctaData:any = {
        title:'It’s your plan made your way.',
        copy: '<p>The first step to digital freedom takes less than a minute. Download the Melon App now.</p>',
        image: image1
    }

  return (
    <div className='contact'>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : 'Melon Mobile | '+PageData.title.rendered}</title>
            <meta name="description" content='Go your own way'/>
        </Helmet>
        <Navigation />
        <ContactBanner 
            title={items[0][0]}
            subtitle={items[0][1]}
            whatsappLink={items[1][1][0]}
            facebookLink={items[2][1][0]}
            twitterLink={items[2][1][1]}
            linkedinLink={items[2][1][2]}
            tiktokLink={items[2][1][3]}
            appStoreLink={items[3][1][0]}
            googlePlayLink={items[3][1][1]}
            instagramLink={items[2][1][4]}
        />
        <DownloadCta 
            title={ctaData.title} 
            copy={ctaData.copy} 
            image={ctaData.image} 
        />
    </div>
  );
};

export default Contact;