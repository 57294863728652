import React, { useEffect, useRef, useState } from 'react'
import Button from './Button'
import HeadingTwo from './HeadingOne'
import HeadingOne from "./HeadingOne";


import Lottie from 'react-lottie';
import { useInView } from 'react-intersection-observer';

//Animations
import studentPlan from '../assets/lottie/melon-mobile-student-melon-animation.json';
import familyPlan from '../assets/lottie/melon-mobile-family-melon-animation.json';
import influencerPlan from '../assets/lottie/melon-mobile-influencer-melon-animation.json';
import gamerPlan from '../assets/lottie/melon-mobile-gamer-melon-animation.json';
import businessPlan from '../assets/lottie/melon-mobile-business-melon-animation.json';
import travellerPlan from '../assets/lottie/melon-mobile-traveller-melon.-animationjson.json';

interface bannerProps {
    title:string,
    paragraph:string,
    includes:any,
    price:string,
    icon:string,
    background:string,
    id: string
}
function PackageBanner({ title, paragraph, includes, price, icon, background, id }: bannerProps) {
    const containerRef = useRef<HTMLDivElement>(null);
    const imgRegex = /.*src="([^"]*)".*/;
    const [inView, setInView] = useState(false);
    const headingRef = React.useRef<HTMLImageElement>(null);
    const lottieRef = React.useRef<HTMLImageElement>(null);
    const [ref, inView2] = useInView({
        triggerOnce: true,
        rootMargin: '200px'
      });

      useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (lottieRef.current) {
          observer.observe(lottieRef.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [lottieRef]);

    //Student Plan Animations
    const studentPlanOptions = {
        loop: true,
        autoplay: true, 
        animationData: studentPlan,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    //Influencer Plan Animations
    const influencerPlanOptions = {
        loop: true,
        autoplay: true, 
        animationData: influencerPlan,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

     //Family Plan Animations
     const familyPlanOptions = {
        loop: true,
        autoplay: true, 
        animationData: familyPlan,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    //Gamer Plan Animations
    const gamerPlanOptions = {
        loop: true,
        autoplay: true, 
        animationData: gamerPlan,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    //Business Plan Animations
    const businessPlanOptions = {
        loop: true,
        autoplay: true, 
        animationData: businessPlan,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    //Traveller Plan Animations
    const travellerPlanOptions = {
        loop: true,
        autoplay: true, 
        animationData: travellerPlan,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        const handleScroll = () => {
          const container = containerRef.current;
          if (!container) return;
        
          const offset = window.pageYOffset;
          const height = container.offsetHeight;
        
          container.style.backgroundPositionY = `${offset * -0.5}px`;

        };
        
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  return (
    <div ref={containerRef} className='package-banner' style={{backgroundImage: `url(${background.replace(imgRegex, '$1')})`}}>
        <div className="circle"></div>
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-11 col-lg-7 package-card">
                    <div className="wrapper">
                        <div className="title">
                            <HeadingOne title={title} />
                        </div>
                        <div className="row body">
                            <div className="col-12 col-sm-4">
                                <div className="price">
                                    <p>{price}<span>per month</span></p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-8 body-right">
                                <div className="cta">
                                    <strong>INCLUDES</strong> <br />
                                    <div>
                                        <img decoding="async" loading="lazy" className="alignnone wp-image-454" src="http://melonmobilecontent.agletinteractive.com/wp-content/uploads/2023/01/Melon-Mobile-Data-Dark-Icon.svg" alt="Melon Mobile Data Dark Icon" width="30" height="30" /> Data { includes[0] }
                                    </div>
                                    <div>
                                        <img decoding="async" loading="lazy" className="alignnone wp-image-454" src="http://melonmobilecontent.agletinteractive.com/wp-content/uploads/2023/01/Melon-Mobile-Call-Dark-Icon.svg" alt="Melon Mobile Data Dark Icon" width="30" height="30" /> Call { includes[1] }
                                    </div>
                                    <div>
                                        <img decoding="async" loading="lazy" className="alignnone wp-image-454" src="http://melonmobilecontent.agletinteractive.com/wp-content/uploads/2023/01/Melon-Mobile-Text-Dark-Icon.svg" alt="Melon Mobile Data Dark Icon" width="30" height="30" /> Text { includes[2] }
                                    </div>
                                    {/* <div dangerouslySetInnerHTML={{ __html: includes[0] }} /> */}
                                    {/* <div dangerouslySetInnerHTML={{ __html: includes[1] }} /> */}
                                    {/* <div dangerouslySetInnerHTML={{ __html: includes[2] }} /> */}
                                </div>
                                <div ref={lottieRef}>
                                    {/* <img className='d-lg-none' src={icon.replace(imgRegex, '$1')} alt="Icon" /> */}
                                    <div className='d-block lottie-image' ref={lottieRef}>
                                        {
                                            title == 'Student Melon' &&
                                            <Lottie options={studentPlanOptions} height={150} width={150} isStopped={!inView} />
                                        }
                                        {
                                            title == 'Family Melon' &&
                                            <Lottie options={familyPlanOptions} height={150} width={150} isStopped={!inView} />
                                        }
                                        {
                                            title == 'Influencer Melon' &&
                                            <Lottie options={influencerPlanOptions} height={150} width={150} isStopped={!inView} />
                                        }
                                        {
                                            title == 'Gamer Melon' &&
                                            <Lottie options={gamerPlanOptions} height={150} width={150} isStopped={!inView} />
                                        }
                                        {
                                            title == 'Business Melon' &&
                                            <Lottie options={businessPlanOptions} height={150} width={150} isStopped={!inView} />
                                        }
                                        {
                                            title == 'Traveller Melon' &&
                                            <Lottie options={travellerPlanOptions} height={150} width={150} isStopped={!inView} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            <div className="cta">
                                <Button 
                                    type='button'
                                    linkType='external'
                                    className='btn btn-primary'
                                    link={'https://melon.selfcare.amdocs-dbs.com/shop/recommended-plans?planId=' + id }
                                    linkText='Buy now'
                                />
                            </div>
                            <p>
                            {paragraph}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PackageBanner