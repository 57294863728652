import React, {useState, useEffect} from 'react';
import Slider from 'rc-slider';
import HeadingHardcoded from './HeadingHardcoded';
import Button from './Button';
import 'rc-slider/assets/index.css';

import dataIconWhite from '../assets/images/packages/Melon-Mobile-Data-light-Icon.svg';
import callIconWhite from '../assets/images/packages/Melon-Mobile-Call-light-Icon.svg';
import smsIconWhite from '../assets/images/packages/Melon-Mobile-Text-light-Icon.svg';
import dataIcon from '../assets/images/packages/Melon-Mobile-Data-Dark-Icon.svg';
import callIcon from '../assets/images/packages/Melon-Mobile-Call-Dark-Icon.svg';
import smsIcon from '../assets/images/packages/Melon-Mobile-Text-Dark-Icon.svg';
import icon1 from '../assets/images/packages/Melon-Mobile-Try-Before-You-Buy.svg';

// Custom functions
import { fetchSingleBYOPOfferings } from '../functions/fetchDataAPI';
import { convertTextToLinkFriendly, convertMinutes, currencyFormatter } from '../helpers/generalFormating';


function OwnPlan() {

    const [dataValue, setDataValue]: any = useState(0);
    const [minutesValue, setMinutesValue]: any = useState(0);
    const [smsValue, setSmsValue]: any = useState(0);

    const [dataBYOP, setDataBYOP]: any = useState([]);
    const [voiceBYOP, setVoiceBYOP]: any = useState([]);
    const [textBYOP, setTextBYOP]: any = useState([]);

    const [displayData, setDisplayData]: any = useState('0 MB');
    const [displayVoice, setDisplayVoice]: any = useState('');
    const [displayText, setDisplayText]: any = useState('');

    const [displayDataRange, setDisplayDataRange]: any = useState([]);
    const [displayVoiceRange, setDisplayVoiceRange]: any = useState([]);
    const [displayTextRange, setDisplayTextRange]: any = useState([]);

    const [displayDataPrice, setDisplayDataPrice]: any = useState(0);
    const [displayVoicePrice, setDisplayVoicePrice]: any = useState(0);
    const [displayTextPrice, setDisplayTextPrice]: any = useState(0);

    const [totalAmount, setTotalAmount]: any = useState('Free');

    const [activeDataPrice, setActiveDataPrice]: any = useState({});
    const [activeVoicePrice, setActiveVoicePrice]: any = useState({});
    const [activeTextPrice, setActiveTextPrice]: any = useState({});
    const [redirectUrl, setRedirectUrl]: any = useState('');

    const handleDataChange = (value: number | number[]) => {

        // console.log( +value - 1  );
        // console.log('BYOP', dataBYOP);
        // console.log( dataBYOP.map( (key: any, index: any) => key.) );
        setDataValue(+value);
        setDisplayData(dataBYOP[+value].name);
        setDisplayDataPrice(dataBYOP[+value].price);
        setActiveDataPrice(dataBYOP[+value]);
        updateTotalAmount();


        // if (Array.isArray(value)) {
        //   setDataValue(value[0]);
        //   setDisplayData(dataBYOP[0].name);
        //   setDisplayDataPrice(dataBYOP[0].price);
        //   updateTotalAmount();
        // } else {
        //   setDataValue(value);
        //   setDisplayData(dataBYOP[value].name);
        //   setDisplayDataPrice(dataBYOP[value].price);
        //   updateTotalAmount();
        // }
        // Send new value to API here
    };

    
    const handleMinutesChange = (value: number | number[]) => {

        setMinutesValue(value);
        setDisplayVoice(voiceBYOP[+value].name);
        setDisplayVoicePrice(voiceBYOP[+value].price);
        setActiveVoicePrice(voiceBYOP[+value]);
        updateTotalAmount();

        // if (Array.isArray(value)) {
        //   setMinutesValue(value[0]);
        //   setDisplayVoice(voiceBYOP[0].name);
        //   setDisplayVoicePrice(dataBYOP[0].price);
        //   updateTotalAmount();
        // } else {
        //   setMinutesValue(value);
        //   setDisplayVoice(voiceBYOP[value].name);
        //   setDisplayVoicePrice(dataBYOP[value].price);
        //   updateTotalAmount();
        // }
        // Send new value to API here

    };
    
    const handleSmsChange = (value: number | number[]) => {

        setSmsValue(value);
        setDisplayText(textBYOP[+value].name);
        setDisplayTextPrice(textBYOP[+value].price);
        setActiveTextPrice(textBYOP[+value]);
        updateTotalAmount();

        // if (Array.isArray(value)) {
        //   setSmsValue(value[0]);
        //   setDisplayText(textBYOP[0].name);
        //   setDisplayTextPrice(dataBYOP[0].price);
        //   updateTotalAmount();
        // } else {
        //   setSmsValue(value);
        //   setDisplayText(textBYOP[value].name);
        //   setDisplayTextPrice(dataBYOP[value].price);
        //   updateTotalAmount();
        // }
        // Send new value to API here
    };

 
    useEffect(() => {
        fetchSliderOptions();
    }, []);

    useEffect( () => {
        updateTotalAmount();
    }, [dataValue, minutesValue, smsValue]);

    useEffect( () => {

        let redirectUrlValue = '';
        
        if ( Object.keys(activeDataPrice).length > 0 && activeDataPrice.id !== '0' ) {
            if( redirectUrl.length > 0){
                redirectUrlValue += '&Data=' + activeDataPrice.id;
            } else {
                redirectUrlValue += 'Data=' + activeDataPrice.id;
            }
        }

        if ( Object.keys(activeTextPrice).length > 0 && activeTextPrice.id !== '0'  ) {
            if( redirectUrl.length > 0){
                redirectUrlValue += '&SMS=' + activeTextPrice.id;
            } else {
                redirectUrlValue += 'SMS=' + activeTextPrice.id;
            }
        }

        if ( Object.keys(activeVoicePrice).length > 0 && activeVoicePrice.id !== '0'  ) {
            if( redirectUrl.length > 0){
                redirectUrlValue += '&Voice=' + activeVoicePrice.id;
            } else {
                redirectUrlValue += 'Voice=' + activeVoicePrice.id;
            }
        }


        setRedirectUrl(redirectUrlValue);

    }, [activeDataPrice, activeVoicePrice, activeTextPrice]);

    // Fetch slider options
    const fetchSliderOptions = () => {
        const filterData: any = {
            'data': 'https://productcatalog.amdocs-dbs.com/productOffering/638eef354ec467f914740efd?depth=2&expand=bundledProductOffering.productOfferingPrice',
            'voice': 'https://productcatalog.amdocs-dbs.com/productOffering/638eeceb9b6d03363717a10d?depth=2&expand=bundledProductOffering.productOfferingPrice',
            'text': 'https://productcatalog.amdocs-dbs.com/productOffering/638ee94ffdde17f39d524976?depth=2&expand=bundledProductOffering.productOfferingPrice'
        };

        Object.keys(filterData).map( ( offeringOption: any, offeringOptionIndex: any ) => {
            fetchSingleBYOPOfferings( filterData[offeringOption])
                .then( ( optionResponse: any  ) => {
                    // console.log(optionResponse);
                    if ( optionResponse ) {
                        createSliderValues( offeringOption, optionResponse.bundledProductOffering);
                    }
                })
                .catch( ( error: any ) => {
                    console.log(error);
                });
        });

        return false;
    }

    const createSliderValues = ( optionName: any, optionValues: any ) => {
        let sliderOptionValues: any = [{ "id": "0", "name": "0", "price": 0}];
        let sliderRangerValues: any = [0];

        // console.log(optionValues);

        optionValues.map( ( option: any, optionIndex: any ) => {
            let selectionObj = {
                "id": option.id,
                "name": option.name,
                "price": option?.productOfferingPrice[0]?.price?.value ? option?.productOfferingPrice[0]?.price?.value : 0
            }

            sliderOptionValues.push(selectionObj);
            sliderRangerValues.push( ((parseInt(option.name) * 1000) / 1000) === 500 && optionName === 'data' ? 0.5 : ((parseInt(option.name) * 1000) / 1000) );
        });

        if( sliderOptionValues.length > 0 ) {
            // console.log('Range values', sliderOptionValues)
            switch (optionName) {
                case 'data':
                    setDisplayData( sliderOptionValues[0].name );
                    setDataBYOP(sliderOptionValues);
                    setDisplayDataRange(sliderRangerValues);
                    setDisplayDataPrice(sliderOptionValues[0].price);
                    break;
                case 'voice':
                    setDisplayVoice( sliderOptionValues[0].name );
                    setVoiceBYOP(sliderOptionValues);
                    setDisplayVoiceRange(sliderRangerValues);
                    setDisplayVoicePrice(sliderOptionValues[0].price);
                    break;
                case 'text':
                    setDisplayText( sliderOptionValues[0].name );
                    setTextBYOP(sliderOptionValues);
                    setDisplayTextRange(sliderRangerValues);
                    setDisplayTextPrice(sliderOptionValues[0].price);
                    break;
                default:
                    break;
            }

            updateTotalAmount();
        }        
    }

    const updateTotalAmount = () => {

        let dataTotal = displayDataPrice;
        let minutesTotal = displayVoicePrice;
        let smsTotal = displayTextPrice;

        let totalValue = dataTotal + minutesTotal + smsTotal;

        setTotalAmount(totalValue > 0 ? totalValue : 'Free');

        console.log('data: ', dataTotal, ' voice: ', minutesTotal, ' sms: ', smsTotal);
    }


  return (
    <div className='own-plan'>
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-7">
                    <div className="sliders">
                        <div className="slider data-slider">
                            <div className='head'>
                                <div className="head-top-row">
                                    <div className="icon">
                                        <img src={dataIconWhite} alt="Data" />
                                    </div>
                                    <h4>Data</h4>
                                </div>
                                <div className="head-bottom-row">
                                    <div className="current-value">
                                        {displayData}
                                    </div>
                                    <div className="current-price">
                                        {currencyFormatter.format(displayDataPrice)}
                                    </div>
                                </div>
                            </div>
                            <Slider
                                value={dataValue}
                                onChange={handleDataChange}
                                min={0}
                                max={dataBYOP.length - 1}
                                step={1}
                            />
                            <div className='labels' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {/* {Array.from({ length: 5 }, (_, i) => (
                                    <div key={i * 25}>{i * 25}</div>
                                ))} */}
                                { displayDataRange && (
                                    displayDataRange.map( (range: any) => (<div key={range}>{range}</div>))
                                )}
                            </div>
                        </div>

                        <div className="slider minutes-slider">
                            <div className='head'>
                                <div className="head-top-row">
                                    <div className="icon">
                                        <img src={callIconWhite} alt="Data" />
                                    </div>
                                    <h4>Voice</h4>
                                </div>
                                <div className="head-bottom-row">
                                    <div className="current-value">
                                        {convertMinutes(displayVoice)}
                                    </div>
                                    <div className="current-price">
                                        {currencyFormatter.format(displayVoicePrice)}
                                    </div>
                                </div>
                            </div>
                            <Slider
                                value={minutesValue}
                                onChange={handleMinutesChange}
                                min={0}
                                max={voiceBYOP.length - 1}
                                step={1}
                            />
                            <div className='labels' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {/* {Array.from({ length: 5 }, (_, i) => (
                                    <div key={i * 25}>{i * 25}</div>
                                ))} */}
                                { displayVoiceRange && (
                                    displayVoiceRange.map( (range: any) => (<div key={range}>{range}</div>))
                                )}
                            </div>
                        </div>

                        <div className="slider sms-slider">
                            <div className='head'>
                                <div className="head-top-row">
                                    <div className="icon">
                                        <img src={smsIconWhite} alt="Data" />
                                    </div>
                                    <h4>SMS</h4>
                                </div>
                                <div className="head-bottom-row">
                                    <div className="current-value">
                                        {displayText}
                                    </div>
                                    <div className="current-price">
                                        {currencyFormatter.format(displayTextPrice)}
                                    </div>
                                </div>
                            </div>
                            <Slider
                                value={smsValue}
                                onChange={handleSmsChange}
                                min={0}
                                max={textBYOP.length - 1}
                                step={1}
                            />
                            <div className='labels' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {/* {Array.from({ length: 5 }, (_, i) => (
                                    <div key={i * 25}>{i * 25}</div>
                                ))} */}
                                { displayTextRange && (
                                    displayTextRange.map( (range: any) => (<div key={range}>{range}</div>))
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-5">
                    <div className='deal'>
                        <div className="header">
                            <HeadingHardcoded title='Your new plan' />
                        </div>
                        <div className="body">
                            <div className={"icon price " + ( totalAmount === "Free" ? '' : 'ai-price-amount-value' ) }>
                                {/* <img src={icon1} alt='Plan' /> */}
                                <p><strong>{totalAmount === "Free" ? totalAmount : currencyFormatter.format(totalAmount) }</strong> <span>per month</span></p>
                            </div>
                            <div className="details">
                                <p>
                                    <img src={dataIcon} alt="Data" />
                                    <strong> Data</strong> {displayData}
                                </p>
                                <p>
                                    <img src={callIcon} alt="Call" />
                                    <strong> Voice</strong> {convertMinutes(displayVoice)}
                                </p>
                                <p>
                                    <img src={smsIcon} alt="Text" />
                                    <strong>SMS</strong> {displayText}
                                </p>
                            </div>

                            {/* <div className="price">
                                <p><strong>R250</strong> <span>per month</span></p>
                            </div> */}

                            <div className="link">
                                <Button 
                                    type='button'
                                    className='btn btn-primary'
                                    link={redirectUrl.length > 0 ? 'https://melon.selfcare.amdocs-dbs.com/shop/custom-plan?' + redirectUrl : '#'}
                                    // link={redirectUrl.length > 0 ? 'https://melon.selfcare.amdocs-dbs.com/shop/custom-plan?Data=638eef024ec467f914740ebc&Voice=638eeca69b6d03363717a0b2&SMS=638ee91ffdde17f39d524930' : '#'}
                                    linkText='Request FREE SIM now'
                                    linkType='external'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default OwnPlan