import React from 'react';
import Slider from 'react-slick';
import { PageData } from "../types/PageData";
import {Link, useLoaderData} from "react-router-dom";
import HeadingTwo from './HeadingTwo';
import Button from './Button';
import lazyLoadImage from '../helpers/LazyLoadImage';

interface sliderProps {
    slideSpeed: number
}

function BannerSlider({slideSpeed}: sliderProps) {
    const PageData = useLoaderData() as PageData;
    const items = PageData.content.no_short_codes;
    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;

    // console.log(items[0])
    const settings:any = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        autoplay: false,
        autoplaySpeed: slideSpeed,
        fade: true
    }

  return (
    <div>
        <div className="bannerSlider">
            <Slider {...settings} >
                    <div className="item">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <HeadingTwo title={items[0][1][0]} />
                                    {items[0][1][2] === '' ? null : <Button type='button' className='btn btn-white' link={items[0][1][2].replace(aiButtonHref, '$1')} linkText={items[0][1][2].replace(aiButtonHref, '$2')}/>}
                                    {items[0][1][4] === '' ? null : <Link to={items[0][1][4].replace(aiButtonHref, '$1')} >{items[0][1][4].replace(aiButtonHref, '$2')}</Link>}
                                </div>
                            </div>
                        </div>
                        {items[0][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[0][0] }} />}
                    </div>

                    <div className="item">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-6"> 
                                    <HeadingTwo title={items[0][3][0]} />
                                    {items[0][3][2] === '' ? null : <Button type='button' className='btn btn-white' linkText={items[0][3][2].replace(aiButtonHref, '$2')} link={items[0][3][2].replace(aiButtonHref, '$1')} />} 
                                    {items[0][3][4] === '' ? null : <Link to={items[0][3][4].replace(aiButtonHref, '$1')} >{items[0][3][4].replace(aiButtonHref, '$2')}</Link>}
                                </div>
                            </div>
                        </div>
                        {items[0][2][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: lazyLoadImage(items[0][2][0]) }} />}
                    </div>

                    <div className="item">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <HeadingTwo title={items[0][5][0]} />
                                     {items[0][5][2] === '' ? null : <Button type='button' className='btn btn-white' link={items[0][5][2].replace(aiButtonHref, '$1')} linkText={items[0][5][2].replace(aiButtonHref, '$2')} />}
                                     {items[0][5][4] === '' ? null : <Link to={items[0][5][4].replace(aiButtonHref, '$1')} >{items[0][5][4].replace(aiButtonHref, '$2')}</Link>}
                                </div>
                            </div>
                        </div>
                        {items[0][4][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: lazyLoadImage(items[0][4][0]) }} />}
                    </div>
            </Slider>
        </div>
    </div>
  )
}

export default BannerSlider