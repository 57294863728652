const fetchData = (type:string, id:number | string | undefined) => {
  if(type === 'page'){
    return fetch(`https://melonmobilecontent.agletinteractive.com/wp-json/wp/v2/pages/${id}`)
        .then((res) => res.json())
  } else {
    return fetch(`https://melonmobilecontent.agletinteractive.com/wp-json/wp/v2/${type}?slug=${id}&_embed`)
        .then((res) => res.json())
  }
};

export default fetchData;