const API_URL = 'https://melonmobilecontent.agletinteractive.com/wp-json/';


const submitNewsLetter = ( email_address: string ) => {
    return new Promise((resolve, reject) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email_address: email_address })
            };
            fetch( API_URL + 'custom/v1/emails/newsletter', requestOptions)
                .then(response => response.json())
                .then(data => resolve(data) );
        } catch (error) {
            reject(error);
        }
    });
};

export { submitNewsLetter };