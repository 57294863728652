import React, { useEffect } from 'react';
import {useLoaderData} from "react-router-dom";
import ErrorBanner from '../components/ErrorBanner';
import { PageData } from "../types/PageData";
import {Helmet} from 'react-helmet';
import Navigation from '../components/Navigation';

const Error404 = () => {
    // Page Data From Router
    const PageData = useLoaderData() as PageData;
    const items = PageData.content.no_short_codes;
    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;
    const yoast:any = PageData.yoast_head_json


  useEffect(() => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);

  return (
      <div>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : 'Melon Mobile | '+PageData.title.rendered}</title>
            <meta name="description" content='Go your own way'/>
        </Helmet>
        <Navigation />
        <ErrorBanner 
            title={items[0][0][0]}
            subtitle={items[0][1][0]}
            link={items[0][1][1].replace(aiButtonHref, '$1')}
            linkText={items[0][1][1].replace(aiButtonHref, '$2')}
            link2={items[0][2][0].replace(aiButtonHref, '$1')}
            linkText2={items[0][2][0].replace(aiButtonHref, '$2')}
            image={items[0][3][0]}
        />
      </div>
  );
};

export default Error404;