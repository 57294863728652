import React, { useRef, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom'
import {PageData} from "../types/PageData";
import Testimonials from '../components/Testimonials';
import BannerSlider from '../components/BannerSlider';
import LatestDeals from '../components/LatestDeals';
import Benefits from '../components/Benefits';
import DownloadCta from '../components/DownloadCta';
import VideoBanner from '../components/VideoBanner';
import {Helmet} from 'react-helmet';
import HeadingTwo from '../components/HeadingTwo';
import Lottie from 'react-lottie';
import { useInView } from 'react-intersection-observer';

//Images 
import image1 from '../assets/images/Melon-Mobile-Your-Plan-Made-Your-Way-CTA.png';
import MelonSteps from '../components/MelonSteps';
import Button from '../components/Button';
import PackagesTabs from '../components/PackagesTabs';
import Navigation from '../components/Navigation';
import animationData from '../assets/lottie/melon-mobile-rocket-animation.json';

const Home = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [inView, setInView] = useState(false);
    const lottieRef = React.useRef<HTMLImageElement>(null);
    const [ref, inView2] = useInView({
        triggerOnce: true,
        rootMargin: '200px'
      });
    // Page Data From Router
    const PageData = useLoaderData() as PageData;
    const ctaData:any = {
        title:'It’s your plan made your way.',
        copy: '<p>The first step to digital freedom takes less than a minute. Download the Melon App now.</p>',
        image: image1
    }

    const items:any = PageData.content.no_short_codes;
    const yoast:any = PageData.yoast_head_json

    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;

    useEffect(() => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);

    useEffect(() => {
        const handleScroll = () => {
          const container = containerRef.current;
          if (!container) return;
    
          const offset = window.pageYOffset;
          const height = container.offsetHeight;
    
          container.style.backgroundPositionY = `${offset * -0.5}px`;
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (lottieRef.current) {
          observer.observe(lottieRef.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [lottieRef]);

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

  return (
    <div className='home'>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{yoast !== undefined ? yoast.og_title : 'Title'}</title>
            <meta name="description" content='Go your own way'/>
        </Helmet>
        <Navigation />
        <BannerSlider slideSpeed={5000} />

        <div ref={containerRef} className="centered-text">
            <div className="container">
                <div className="row">
                    <div className="col-12" ref={lottieRef}>
                        {items[1][0] && <div className='d-lg-none' dangerouslySetInnerHTML={{ __html: items[1][0] }} />}
                        <div className='d-none d-lg-block lottie-image' ref={ref}>
                            <Lottie options={defaultOptions} height={300} width={400} isStopped={!inView} />
                        </div>
                    </div>
                    <div className="col-12 col-md-9 mx-auto">
                        <HeadingTwo title={items[1][1][0]} />
                    </div>
                    <div className="col-12 col-md-11 mx-auto">
                        {items[1][2][0] && <div dangerouslySetInnerHTML={{ __html: items[1][2][0] }} />}
                        {items[1][2][1] && <div dangerouslySetInnerHTML={{ __html: items[1][2][1] }} />}
                    </div>
                    <div className="link text-center">
                        <Button 
                            type='button'
                            className='btn btn-primary'
                            link={items[1][3][0].replace(aiButtonHref, '$1')}
                            linkText={items[1][3][0].replace(aiButtonHref, '$2')}
                        />
                    </div>
                </div>
            </div>
        </div>

        <PackagesTabs />

        <MelonSteps 
            title={items[2][0][0]} 
            introSubtitle={items[2][1][0]} 
            image1={items[2][2][0]} 
            image2={items[2][4][0]} 
            image3={items[2][6][0]} 
            copy1={items[2][3][0]} 
            copy2={items[2][5][0]} 
            copy3={items[2][7][0]} 
            link={items[2][8][0].replace(aiButtonHref, '$1')}
            linkText={items[2][8][0].replace(aiButtonHref, '$2')} 
        />
        <Benefits
            title={items[3][0][0]}
            image1={items[3][1][0]}
            image2={items[3][2][0]}
            image3={items[3][3][0]}
            image4={items[3][4][0]}
            subTitle1={items[3][1][1]}
            subTitle2={items[3][2][1]}
            subTitle3={items[3][3][1]}
            subTitle4={items[3][4][1]}
            content1={items[3][1][2]}
            content2={items[3][2][2]}
            content3={items[3][3][2]}
            content4={items[3][4][2]}
            link={items[3][5][0].replace(aiButtonHref, '$1')}
            linkText={items[3][5][0].replace(aiButtonHref, '$2')}
        />
        <VideoBanner
            video={items[4][1]+items[4][2]}
            videoThumbnail={items[4][0]}
        />
        <LatestDeals
            title={items[5][0][0]}
            image1={items[5][1][0]}
            image2={items[5][1][1]}
            link={items[5][1][2].replace(aiButtonHref, '$1')}
            linkText={items[5][1][2].replace(aiButtonHref, '$2')}
        />
        <Testimonials 
            slideSpeed={5000}
            title={items[6][0][0]}
            copy1={items[6][1][0]}
            icon1={items[6][1][1]}
            name1={items[6][1][2]}
            copy2={items[6][2][0]}
            icon2={items[6][2][1]}
            name2={items[6][2][2]}
            copy3={items[6][3][0]}
            icon3={items[6][3][1]}
            name3={items[6][3][2]}
            copy4={items[6][4][0]}
            icon4={items[6][4][1]}
            name4={items[6][4][2]}
            copy5={items[6][5][0]}
            icon5={items[6][5][1]}
            name5={items[6][5][2]}
        />
        <DownloadCta 
            title={ctaData.title} 
            copy={ctaData.copy} 
            image={ctaData.image} 
        />
    </div>
  );
};

export default Home;