import React, { useEffect } from 'react';
import {useLoaderData} from "react-router-dom";
import { PageData } from "../types/PageData";
import {Helmet} from 'react-helmet';
import Banner from '../components/Banner';

import background from '../assets/images/Melon-Mobile-Banner-Background.jpg';
import HeadingTwo from '../components/HeadingTwo';
import Navigation from '../components/Navigation';

const Legal = () => {
  // Page Data From Router
    const PageData = useLoaderData() as PageData
    const items = PageData.content.no_short_codes;
    const yoast:any = PageData.yoast_head_json


  useEffect(() => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);

  return (
    <div>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : 'Melon Mobile | '+PageData.title.rendered}</title>
            <meta name="description" content='Go your own way'/>
        </Helmet>
        <Navigation />
        <Banner 
            badge={PageData.title.rendered} 
            title={items[0][0]} 
            background={background} 
        />
        <div className="container legal-content">
            <div className="row">
                <div className="co-12">
                    {items[1][0][0] && <HeadingTwo title={items[1][0][0]} />}
                    {items[1][1][0] && <p>{items[1][1][0]}</p>}
                </div>
            </div>

            <div className="row">
                <div className="co-12">
                    {items[2][0][0] && <HeadingTwo title={items[2][0][0]} />}
                    {items[2][1][0] && <p>{items[2][1][0]}</p>}
                </div>
            </div>

            <div className="row">
                <div className="co-12">
                    {items[3][0][0] && <HeadingTwo title={items[3][0][0]} />}
                    {items[3][1][0] && <p>{items[3][1][0]}</p>}
                </div>
            </div>

            <div className="row">
                <div className="co-12">
                    {items[4][0][0] && <HeadingTwo title={items[4][0][0]} />}
                    {items[4][1][0] && <p>{items[4][1][0]}</p>}
                </div>
            </div>

            <div className="row">
                <div className="co-12">
                    {items[5][0][0] && <HeadingTwo title={items[5][0][0]} />}
                    {items[5][1][0] && <p>{items[5][1][0]}</p>}
                </div>
            </div>

            <div className="row">
                <div className="co-12">
                    {items[6][0][0] && <HeadingTwo title={items[6][0][0]} />}
                    {items[6][1][0] && <p>{items[6][1][0]}</p>}
                </div>
            </div>
        </div>
    </div>
  );
};

export default Legal;