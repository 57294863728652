import React from 'react';
import {RouterProvider} from 'react-router-dom'
import Cookies from './components/Cookies';
import {router} from "./router";
import {Helmet} from "react-helmet";

function App() {
    return (
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Melon Mobile</title>
          <meta name="description" content="Melon Mobile" data-react-helmet="true" />
        </Helmet>
        <RouterProvider router={router}/>
      </div>
    );
  }

export default App;