import React, { useEffect, useState } from 'react';
import HeadingAnimation from "../helpers/HeadingAnimation";
import useHasCenter from "../helpers/useHasCenter";

interface headingProps {
    title: string
}

function HeadingTwo({title}: headingProps) {
    const [inView, setInView] = useState(false);
    const headingRef = React.useRef<HTMLImageElement>(null);

    const hasCenter = useHasCenter(title);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (headingRef.current) {
          observer.observe(headingRef.current);
        }
        return () => {
          observer.disconnect();
        };
      }, [headingRef]);
  return (
    <div>
        {/*<div ref={headingRef}*/}
        {/*     className={`reveal-heading ${inView ? 'slide-up' : ''}`} dangerouslySetInnerHTML={{ __html: title }}/>*/}
      <div ref={headingRef} className={hasCenter ? 'ai-text-center': ''}>
        <h2>{HeadingAnimation(title, inView)}</h2>
      </div>
    </div>
  )
}

export default HeadingTwo