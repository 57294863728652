import React, { useEffect, useState } from 'react';
import {NavLink, useLoaderData} from "react-router-dom";
import { PageData } from "../types/PageData";
import Banner from '../components/Banner';
import background from '../assets/images/Melon-Mobile-Banner-Background.jpg';
import ContentImage from '../components/ContentImage';
import VideoBanner from '../components/VideoBanner';
import DownloadCta from '../components/DownloadCta';
import Button from '../components/Button';
import Lottie from 'react-lottie';
import { useInView } from 'react-intersection-observer';

import image1 from '../assets/images/Melon-Mobile-Your-Plan-Made-Your-Way-CTA.png';
import {Helmet} from 'react-helmet';
import HeadingTwo from '../components/HeadingTwo';
import Navigation from '../components/Navigation';

//Animations
import availableInSA from '../assets/lottie/melon-mobile-available-in-south-africa-animation.json';
import rocketAnimation from '../assets/lottie/melon-mobile-rocket-animation.json';

const Troubleshooting = () => {
    // Page Data From Router
    const PageData = useLoaderData() as PageData;
    const items = PageData.content.no_short_codes; 
    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;
    const yoast:any = PageData.yoast_head_json
    const [inView, setInView] = useState(false);
    const lottieRef = React.useRef<HTMLImageElement>(null);
    const lottieRef2 = React.useRef<HTMLImageElement>(null);
    const [ref, inView2] = useInView({
        triggerOnce: true,
        rootMargin: '200px'
      });

      useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (lottieRef.current) {
          observer.observe(lottieRef.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [lottieRef]);
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (lottieRef2.current) {
          observer.observe(lottieRef2.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [lottieRef2]);

    //Available in SA
    const availableInSAOptions = {
        loop: true,
        autoplay: true, 
        animationData: availableInSA,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    //Rocket Animation
    const rocketAnimationOptions = {
        loop: true,
        autoplay: true, 
        animationData: rocketAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

  useEffect(() => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);

  return (
    <div className='self-help'>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : 'Melon Mobile | '+PageData.title.rendered}</title>
            <meta name="description" content='Go your own way'/>
        </Helmet>
        <Navigation />
        <Banner badge={PageData.title.rendered} title={items[0][0][0]+'<br />'+items[0][0][1] + items[0][0][2]} background={background} />

        <div className="centered-text pb-5 title-container">
            <div className="container">
                <div className="row">
                    <div className="col-12 mb-5">
                        <div ref={lottieRef}>
                            <div className='d-block lottie-image' ref={ref}>
                                <Lottie options={availableInSAOptions} height={250} width={250} isStopped={!inView} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-9 mx-auto">
                        {items[1][1][0] && <HeadingTwo title={items[1][1][0]} />}
                    </div>
                    <div className="col-12 col-md-12 mx-auto">
                        {items[1][2][0] && <div dangerouslySetInnerHTML={{ __html: items[1][2][0] }} />}
                        {items[1][2][1] && <div dangerouslySetInnerHTML={{ __html: items[1][2][1] }} />}
                        {items[1][2][2] && <div dangerouslySetInnerHTML={{ __html: items[1][2][2] }} />}
                    </div>
                </div>
            </div>
        </div>

        <div className="pb-3">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-1"></div>
                    <div className="col-12 col-lg-4 list-column">
                        <h3>
                        {items[2][0] && <div dangerouslySetInnerHTML={{ __html: items[2][0] }} />}
                        </h3>
                        <ul>
                           <li><h5>1</h5> <span>{items[2][1][0]}</span></li>
                           <li><h5>2</h5> <span>{items[2][1][1]}</span></li>
                           <li><h5>3</h5> <span>{items[2][1][2]}</span></li>
                           <li><h5>4</h5> <span><div dangerouslySetInnerHTML={{ __html: items[2][1][3] + items[2][1][4] }} /></span></li>
                           <li><h5>5</h5> <span>{items[2][2][0]}</span></li>
                        </ul>
                    </div>
                    <div className="col-12 col-lg-1"></div>
                    <div className="col-12 col-lg-6 list-column">
                        <h3>
                        {items[3][0] && <div dangerouslySetInnerHTML={{ __html: items[3][0] }} />}
                        </h3>
                        <ul>
                            <li><h5>1</h5> <span>{items[3][1][0]}</span></li>
                            <li><h5>2</h5> <span>{items[3][1][1].replace('&amp;', '&')}</span></li>
                            <li><h5>3</h5> <span>{items[3][1][2].replace('&gt;', '>')}</span></li>
                            <li><h5>4</h5> <span>{items[3][1][3]}</span></li>
                            <li><h5>5</h5> <span><div dangerouslySetInnerHTML={{ __html: items[3][1][4] + items[3][1][5] }} /></span></li>
                        </ul>
                    </div>
                    {/* <div className="col-12 col-lg-1"></div> */}
                </div>
            </div>
        </div>

        <div className="centered-text pb-6 title-container">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div ref={lottieRef2}>
                            <div className='d-block lottie-image' ref={ref}>
                                <Lottie options={rocketAnimationOptions} height={500} width={500} isStopped={!inView} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-9 mx-auto">
                        {items[4][1][0] && <HeadingTwo title={items[4][1][0]} />}
                    </div>
                    <div className="col-12 col-md-9 mx-auto">
                        {items[4][2][0] && <div dangerouslySetInnerHTML={{ __html: items[4][2][0] }} />}
                        {items[4][2][1] && <div dangerouslySetInnerHTML={{ __html: items[4][2][1] }} />}
                        {items[4][2][2] && <div dangerouslySetInnerHTML={{ __html: items[4][2][2] }} />}
                    </div>
                    <div className="col-12 col-md-9 mx-auto before-starting">
                        {items[4][3][0] && <div dangerouslySetInnerHTML={{ __html: items[4][3][0]}} />} 
                    </div>
                </div>
            </div>
        </div>

        <div className="centered-text pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10 list-column flex-column">
                        <ul>
                            <div className="d-flex">
                                { items[5].length > 0 && items[5].map((listItem: any,index: any)=>{
                                    return <div className="list-item"><li><h5>{index + 1}</h5><span>{listItem}</span></li></div>
                                })}
                            </div>
                        </ul>
                    </div>
                    <div className="col-1"></div>
                </div>
            </div>
        </div>

        <div className="centered-text pb-5 note">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {items[6][0]} <a href={items[6][1].replace('<a href=\"','').replace('\">','')}>{items[6][2]}</a>  
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Troubleshooting;