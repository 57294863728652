import React, { useEffect, useState } from 'react';
import {Link, useLoaderData} from "react-router-dom";
import { PageData } from "../types/PageData";
import SingleBlogBanner from '../components/SingleBlogBanner';
import DownloadCta from '../components/DownloadCta';
import { Helmet } from 'react-helmet';

import image1 from '../assets/images/Melon-Mobile-Your-Plan-Made-Your-Way-CTA.png';
import Navigation from '../components/Navigation';
import ShareLinks from '../components/ShareLinks';

const BlogSingle = () => {
    const [url, setUrl] = useState('');
    // Page Data From Router
    const [PageData] = useLoaderData() as PageData[]
    // const [prevImageUrl, setprevImageUrl] = useState;
    // const [nextImageUrl, setnextImageUrl] = useState;
    const items = PageData.content.no_short_codes;
    const yoast:any = PageData.yoast_head_json
    const title = PageData.title.rendered;
    const postMedia:any = PageData._embedded;
    const postDate = new Date(PageData.date); 
    const options:any = { day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(postDate);
    const postImage = postMedia['wp:featuredmedia'][0].media_details.sizes.full.source_url;
    const imageTitle = postMedia['wp:featuredmedia'][0].media_details.sizes.full.file;
    const postCategory = postMedia['wp:term'][0][0].name;

    useEffect(() => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);

    useEffect(() => {
        setUrl(window.location.href);
    }, []);

    const ctaData:any = {
        title:'It’s your plan made your way.',
        copy: '<p>The first step to digital freedom takes less than a minute. Download the Melon App now.</p>',
        image: image1
    }

  return (
    <div className='single-blog'>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : 'Melon Mobile | '+PageData.title.rendered}</title>
            <meta name="description" content='Go your own way'/>
        </Helmet>
        <Navigation />
        <SingleBlogBanner
            postDate={formattedDate}
            title={title}
            image={postImage}
            imageName={imageTitle}
        />

        <div className="share-wrapper container">
            <ShareLinks url={url} title={title} />
        </div>

        <div className="container">
            <div className="tag"><span>{postCategory}</span></div>
        </div>

        <div className="container post-content">
            <div className="row">
                <div className="col-12">
                    {items[0][0][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[0][0][0] }} />}
                    {items[0][1][0] === '' ? null : <p>{items[0][1][0]}</p>}
                </div>
            </div>
            <div className="row">
                <div className="col-12 image">
                    {items[1][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[1][0] }} />}
                    {items[1][1][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[1][1][0]+items[1][1][1]+'</p>' }} />}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {items[2][0] === '' ? null : <p>{items[2][0]}</p>}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {items[3][0][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[3][0][0] }} />}
                    {items[3][1][0] === '' ? null : <p>{items[3][1][0]}</p>}
                </div>
            </div>
            <div className="row">
                <div className="col-12 image">
                    {items[4][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][0] }} />}
                    {items[4][1][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][1][0]+items[4][1][1]+'</p>' }} />}
                </div>
            </div>
        </div>

        <DownloadCta 
            title={ctaData.title} 
            copy={ctaData.copy} 
            image={ctaData.image} 
        />

        <div className="post-links">
            {PageData.previous &&
                <Link className='prev' to={'/blog/'+PageData.previous.slug} >
                    <i className="icon"></i> 
                    <div>
                        <p>Previous blog post</p>
                        <h4>{PageData.previous.title}</h4>
                    </div>
                    <img src={PageData.previous.thumbnail} alt={PageData.previous.title} />
                </Link>
            }
            {PageData.next &&
                <Link className='next' to={'/blog/'+PageData.next.slug} >
                    <div>
                        <p>Next blog post</p>
                        <h3>{PageData.next.title}</h3>
                    </div>
                    <i className="icon"></i>
                    <img src={PageData.next.thumbnail} alt={PageData.next.title} />
                </Link>
            }
        </div>

    </div>
  );
};
export default BlogSingle;