import React, { useEffect, useState } from 'react';
import { PageData } from "../types/PageData";
import {useLoaderData} from "react-router-dom";
import Banner from '../components/Banner';
import MelonSteps from '../components/MelonSteps';
import Accordion from '../components/Accordion';
import DownloadCta from '../components/DownloadCta';
import {Helmet} from 'react-helmet';

import background from '../assets/images/Melon-Mobile-Banner-Background.jpg';
import image1 from '../assets/images/Melon-Mobile-Your-Plan-Made-Your-Way-CTA.png';
import HeadingTwo from '../components/HeadingTwo';
import Navigation from '../components/Navigation';

const Deals = () => {
    const [inView, setInView] = useState(false);
    const imgRef = React.useRef<HTMLImageElement>(null);
    const [inView2, setInView2] = useState(false);
    const imgRef2 = React.useRef<HTMLImageElement>(null);
    const [selectedChild, setSelectedChild] = useState<string | null>(null);

      const handleClick = (childId: string) => {
        setSelectedChild(childId);
    };
    // Page Data From Router
    const PageData = useLoaderData() as PageData
    const items = PageData.content.no_short_codes;
    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;
    const yoast:any = PageData.yoast_head_json


  const ctaData:any = {
        title:'It’s your plan made your way.',
        copy: '<p>The first step to digital freedom takes less than a minute. Download the Melon App now.</p>',
        image: image1
    }

    useEffect(() => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (imgRef.current) {
          observer.observe(imgRef.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [imgRef]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView2(true);
            }
          });
        });
        if (imgRef2.current) {
          observer.observe(imgRef2.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [imgRef2]);

  return (
    <div className='deals'>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : 'Melon Mobile | '+PageData.title.rendered}</title>
            <meta name="description" content='Go your own way'/>
        </Helmet>
        <Navigation />
        <Banner 
            badge={PageData.title.rendered} 
            title={items[0][0][0]+items[0][0][1]} 
            background={background} 
        />

        <div className="ai-large-image-banner container">
            <div className="row">
                <div className="col-12 col-md-10 col-lg-8 mx-auto">
                    {items[1][0][0] === '' ? null : <div className='normal-weight-heading'><HeadingTwo title={items[1][0][0]} /></div>}
                </div>
                <div className="col-12">
                    {items[1][1][0] === '' ? null : <div ref={imgRef}
                    className={`image-reveal ${inView ? 'slide-up' : ''}`} dangerouslySetInnerHTML={{ __html: items[1][1][0] }} />}
                </div>
                <div className="col-12 col-md-10 col-lg-11 mx-auto text-center">
                    {items[1][1][1] === '' ? null : <p>{items[1][1][1]}</p>}
                </div>
            </div>
        </div>

        <MelonSteps 
            title={items[2][0][0]} 
            introSubtitle='' 
            image1={items[2][1][0]} 
            image2={items[2][3][0]} 
            image3={items[2][5][0]} 
            copy1={items[2][2][0]+items[2][2][1]} 
            copy2={items[2][4][0]+items[2][4][1]} 
            copy3={items[2][6][0]+items[2][6][1]} 
            link={items[2][7][0].replace(aiButtonHref, '$1')}
            linkText={items[2][7][0].replace(aiButtonHref, '$2')} 
        />

        {/* <div className="bg-light">
            <div className="ai-large-image-banner container">
                <div className="row">
                    <div className="col-12 col-md-10 col-lg-9 mx-auto">
                        {items[3][0][0] === '' ? null : <div className='normal-weight-heading'><HeadingTwo title={items[3][0][0]} /></div>}
                    </div>
                    <div className="col-12">
                        {items[3][1][0] === '' ? null : <div ref={imgRef2}
                        className={`image-reveal ${inView2 ? 'slide-up' : ''}`} dangerouslySetInnerHTML={{ __html: items[3][1][0] }} />}
                    </div>
                    <div className="col-12 col-md-10 col-lg-9 mx-auto text-center">
                        {items[3][1][1] === '' ? null : <p>{items[3][1][1]}</p>}
                    </div>
                </div>
            </div>

            <div className="container ai-column-text">
                {items[4][0][0] === '' ? null : <HeadingTwo title={items[4][0][0]} />}
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-3 text-center">
                        {items[4][1][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[2][1][0]}} />}
                        {items[4][1][1] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][1][1]}} />}
                        {items[4][1][2] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][1][2]}} />}
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 text-center">
                        {items[4][2][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][2][0]}} />}
                        {items[4][2][1] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][2][1]}} />}
                        {items[4][2][2] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][2][2]}} />}
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 text-center">
                        {items[4][3][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][3][0]}} />}
                        {items[4][3][1] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][3][1]}} />}
                        {items[4][3][2] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][3][2]}} />}
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 text-center">
                        {items[4][4][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][4][0]}} />}
                        {items[4][4][1] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][4][1]}} />}
                        {items[4][4][2] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][4][2]}} />}
                    </div>
                </div>
            </div>
        </div> */}

        <div className="container accordion-wrapper">
            {items[5][0][0] === '' ? null : <HeadingTwo title={items[5][0][0]} />}

            <Accordion 
                id="1" onClick={handleClick} isSelected={selectedChild === '1'}
                question={items[5][1][0]}
                answer={items[5][1][1]}
            />
            <Accordion 
                id="2" onClick={handleClick} isSelected={selectedChild === '2'}
                question={items[5][1][2]}
                answer={items[5][1][3]}
            />
            <Accordion 
                id="3" onClick={handleClick} isSelected={selectedChild === '3'}
                question={items[5][1][4]}
                answer={items[5][1][5]}
            />
            <Accordion
                id="4" onClick={handleClick} isSelected={selectedChild === '4'} 
                question={items[5][1][6]}
                answer={items[5][1][7]}
            />
        </div>

        <DownloadCta 
            title={ctaData.title} 
            copy={ctaData.copy} 
            image={ctaData.image} 
        />
    </div>
  );
};

export default Deals;