import React, { useEffect } from 'react';
import {useLoaderData} from "react-router-dom";
import { PageData } from "../types/PageData";
import {Helmet} from 'react-helmet';
import Banner from '../components/Banner';

import background from '../assets/images/Melon-Mobile-Banner-Background.jpg';
import HeadingTwo from '../components/HeadingTwo';
import Navigation from '../components/Navigation';


function TermsOfUse() {
    const PageData = useLoaderData() as PageData
    const items = PageData.content.no_short_codes;
    const yoast:any = PageData.yoast_head_json
    console.log(items);
    const regex = /,/ig;

    useEffect(() => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);
  return (
    <div>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : 'Melon Mobile | '+PageData.title.rendered}</title>
            <meta name="description" content='Go your own way'/>
        </Helmet>
        <Navigation />
        <Banner 
            badge={PageData.title.rendered} 
            title={items[0][0]} 
            background={background} 
        />

        <div className="container legal-content">
            <div className="row">
                <div className="col-12">
                    {items[1][0][0] && <HeadingTwo title={items[1][0][0]} />}
                    {items[1][1] && <div dangerouslySetInnerHTML={{ __html: items[1][1] }} />}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {items[2][0][0] && <HeadingTwo title={items[2][0][0]} />}
                    {items[2][1] && <div dangerouslySetInnerHTML={{ __html: items[2][1].join('') }} />}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    {items[3][0][0] && <HeadingTwo title={items[3][0][0]} />}
                    {items[3][1] && <div dangerouslySetInnerHTML={{ __html: items[3][1].join('') }} />}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    {items[4][0][0] && <HeadingTwo title={items[4][0][0]} />}
                    {items[4][1] && <div dangerouslySetInnerHTML={{ __html: items[4][1].join('') }} />}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    {items[5][0][0] && <HeadingTwo title={items[5][0][0]} />}
                    {items[5][1] && <div dangerouslySetInnerHTML={{ __html: items[5][1].join('') }} />}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    {items[6][0][0] && <HeadingTwo title={items[6][0][0]} />}
                    {items[6][1] && <div dangerouslySetInnerHTML={{ __html: items[6][1].join('') }} />}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    {items[7][0][0] && <HeadingTwo title={items[7][0][0]} />}
                    {items[7][1] && <div dangerouslySetInnerHTML={{ __html: items[7][1].join('')+items[7][2]['paragraphs'] }} />}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    {items[8][0][0] && <HeadingTwo title={items[8][0][0]} />}
                    {items[8][1] && <div dangerouslySetInnerHTML={{ __html: items[8][1].join('') }} />}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    {items[9][0][0] && <HeadingTwo title={items[9][0][0]} />}
                    {items[9][1] && <div dangerouslySetInnerHTML={{ __html: items[9][1].join('') }} />}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    {items[10][0][0] && <HeadingTwo title={items[10][0][0]} />}
                    {items[10][1] && <div dangerouslySetInnerHTML={{ __html: items[10][1].join('') }} />}
                </div>
            </div>
            
            <div className="row">
                <div className="col-12">
                    {items[11][0][0] && <HeadingTwo title={items[11][0][0]} />}
                    {items[11][1] && <div dangerouslySetInnerHTML={{ __html: items[11][1].join('') }} />}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    {items[12][0][0] && <HeadingTwo title={items[12][0][0]} />}
                    {items[12][1] && <div dangerouslySetInnerHTML={{ __html: items[12][1].join('') }} />}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    {items[13][0][0] && <HeadingTwo title={items[13][0][0]} />}
                    {items[13][1] && <div dangerouslySetInnerHTML={{ __html: items[13][1].join('') }} />}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    {items[14][0][0] && <HeadingTwo title={items[14][0][0]} />}
                    {items[14][1] && <div dangerouslySetInnerHTML={{ __html: items[14][1].join('') }} />}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    {items[15][0][0] && <HeadingTwo title={items[15][0][0]} />}
                    {items[15][1] && <div dangerouslySetInnerHTML={{ __html: items[15][1].join('') }} />}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    {items[16][0][0] && <HeadingTwo title={items[16][0][0]} />}
                    {items[16][1] && <div dangerouslySetInnerHTML={{ __html: items[16][1].join('') }} />}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    {items[17][0][0] && <HeadingTwo title={items[17][0][0]} />}
                    {items[17][1][1]['paragraphs'] && <div dangerouslySetInnerHTML={{ __html: items[17][1][1]['paragraphs'].join('') }} />}
                    {items[17][1][0] && <p>{items[17][1][0]}</p>}
                    {items[17][2] && <div dangerouslySetInnerHTML={{ __html: items[17][2].join('') }} />}
                    {items[17][3][1] && <div dangerouslySetInnerHTML={{ __html: items[17][3][1]['paragraphs'] }} />}
                    {items[17][3][0] && <p>{items[17][3][0]}</p>}
                </div>
            </div>
        </div>
    </div>
  )
}

export default TermsOfUse