import React, { useEffect, useState } from 'react';
import {NavLink, useLoaderData} from "react-router-dom";
import { PageData } from "../types/PageData";
import Banner from '../components/Banner';
import background from '../assets/images/Melon-Mobile-Banner-Background.jpg';
import ContentImage from '../components/ContentImage';
import VideoBanner from '../components/VideoBanner';
import DownloadCta from '../components/DownloadCta';
import Button from '../components/Button';
import Lottie from 'react-lottie';
import { useInView } from 'react-intersection-observer';

import image1 from '../assets/images/Melon-Mobile-Your-Plan-Made-Your-Way-CTA.png';
import {Helmet} from 'react-helmet';
import HeadingTwo from '../components/HeadingTwo';
import Navigation from '../components/Navigation';

//Animations
import aSeedWasPlanted from '../assets/lottie/melon-mobile-a-seed-was-planted-animation.json';

const WhoWeAre = () => {
    // Page Data From Router
    const PageData = useLoaderData() as PageData;
    const items = PageData.content.no_short_codes; 
    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;
    const yoast:any = PageData.yoast_head_json
    const [inView, setInView] = useState(false);
    const lottieRef = React.useRef<HTMLImageElement>(null);
    const [ref, inView2] = useInView({
        triggerOnce: true,
        rootMargin: '200px'
      });

      useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (lottieRef.current) {
          observer.observe(lottieRef.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [lottieRef]);

    //A seed was planted
    const aSeedWasPlantedOptions = {
        loop: true,
        autoplay: true, 
        animationData: aSeedWasPlanted,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

  useEffect(() => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);

    const ctaData:any = {
        title:'It’s your plan made your way.',
        copy: '<p>The first step to digital freedom takes less than a minute. Download the Melon App now.</p>',
    image: image1
    }

  return (
    <div className='who-we-are'>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : 'Melon Mobile | '+PageData.title.rendered}</title>
            <meta name="description" content='Go your own way'/>
        </Helmet>
        <Navigation />
        <Banner badge={PageData.title.rendered} title={PageData.content.no_short_codes[0][0][0]} background={background} />

        <div className="centered-text pb-7">
            <div className="container">
                <div className="row">
                    <div className="col-12 mb-5">
                        <div ref={lottieRef}>
                            {items[1][0] && <div className='d-lg-none' dangerouslySetInnerHTML={{ __html: items[1][0] }} />}
                            <div className='d-none d-lg-block lottie-image' ref={ref}>
                                <Lottie options={aSeedWasPlantedOptions} height={150} width={150} isStopped={!inView} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-9 mx-auto">
                        {items[1][1][0] && <HeadingTwo title={items[1][1][0]} />}
                    </div>
                    <div className="col-12 col-md-9 mx-auto">
                        {items[1][2][0] && <div dangerouslySetInnerHTML={{ __html: items[1][2][0] }} />}
                        {items[1][2][1] && <div dangerouslySetInnerHTML={{ __html: items[1][2][1] }} />}
                        {items[1][2][2] && <div dangerouslySetInnerHTML={{ __html: items[1][2][2] }} />}
                    </div>
                </div>
                <div className="links">
                    <Button type='button' linkText={items[1][3][0].replace(aiButtonHref, '$2')} link={items[1][3][0].replace(aiButtonHref, '$1')} className='btn btn-primary' />
                    <Button type='button' linkText={items[1][4][0].replace(aiButtonHref, '$2')} link={items[1][4][0].replace(aiButtonHref, '$1')} className='btn btn-primary' />
                </div>
            </div>
        </div>

        <ContentImage 
            layout='image left'
            image={items[2][0]}
            title={items[2][1]}
            copy={items[2][2]}
            list=''
            link={items[2][3].replace(aiButtonHref, '$1')}
            linkText={items[2][3].replace(aiButtonHref, '$2')}
            numbers=''
        />
        <ContentImage 
            layout='image right'
            image={items[3][0]}
            title={items[3][1]}
            copy={items[3][2]}
            list=''
            link={items[3][3].replace(aiButtonHref, '$1')}
            linkText={items[3][3].replace(aiButtonHref, '$2')}
            numbers=''
        />
        <ContentImage 
            layout='image left'
            image={items[4][0]}
            title={items[4][1]}
            copy={items[4][2]}
            list=''
            link={items[4][3].replace(aiButtonHref, '$1')}
            linkText={items[4][3].replace(aiButtonHref, '$2')}
            numbers=''
        />

        <VideoBanner 
            video={items[5][1]+items[5][2]} 
            videoThumbnail={items[5][0]} 
        />
        <DownloadCta 
            title={ctaData.title} 
            copy={ctaData.copy} 
            image={ctaData.image} 
        />

    </div>
  );
};

export default WhoWeAre;