import React, {useEffect, useRef, useState} from 'react';
import playIcon from '../assets/images/icons/Melon-Mobile-Play.svg'
interface videoProps  {
    video: string,
    videoThumbnail: string,
}

function VideoBanner({ video, videoThumbnail }: videoProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [hasClass, setHasClass] = useState(false);

  const iframeRef = useRef<HTMLDivElement>(null)

  useEffect(()=>{
    if(iframeRef.current){
      let url = iframeRef.current!.children[0].getAttribute('src')
      iframeRef.current!.children[0].setAttribute('src', url+'&autoplay=true')
      iframeRef.current.children[0].setAttribute('width', '1199')
      iframeRef.current.children[0].setAttribute('height', '600')
    }
  })

    
  const handleMouseEnter = () => {
      setHover(true);
  };
  
  const handleMouseLeave = () => {
      setHover(false);
  };
  
  const handleHover = () => {
      if (hasClass) {
          setHasClass(false);
      } else {
          setHasClass(true);
          if (500) {
          setTimeout(() => {
              setHasClass(false);
          }, 500);
          }
      }
  };

  return (
    <div className='video-banner'>
      <div className="container thumbnail-wrapper" onClick={() => setIsOpen(true)}>
        <img className='play-icon' src={playIcon} alt="Play" />
        {videoThumbnail && <div dangerouslySetInnerHTML={{ __html: videoThumbnail }} />}
      </div>
      {isOpen && (
        <>
            <div className="modal-overlay" onClick={() => setIsOpen(false)} >
            </div>
            <button className={`close ${hasClass ? '' : ""}`} data-animation='xMarks' data-remove={500} onMouseEnter={handleHover} onMouseLeave={() => setHasClass(false)}  onClick={() => setIsOpen(false)}></button>
            <div className="modal">
                {video && <div ref={iframeRef} className='modal-content' dangerouslySetInnerHTML={{ __html: video }} /> }
            </div>
        </>
      )}
    </div>
  );
}

export default VideoBanner;